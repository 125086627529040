import { yupResolver } from '@hookform/resolvers/yup';
import valid from 'card-validator';
import { HeadFC, navigate } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import diners from '../../assets/diners-icn.svg';
import discover from '../../assets/discover-icn.svg';
import mastercard from '../../assets/mastercard-icn.svg';
import padlock from '../../assets/padlock.svg';
import radioButton from '../../assets/radio-button.svg';
import visa from '../../assets/visa-icn.svg';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { ErrorAlert } from '../../components/ErrorAlert';
import { Body2 } from '../../components/Fonts/Body';
import { Caption1, Caption2 } from '../../components/Fonts/Caption';
import { Footnote } from '../../components/Fonts/Footnote';
import { H5 } from '../../components/Fonts/HTags';
import Footer from '../../components/Footer';
import { TextField } from '../../components/Input';
import { Line } from '../../components/Line';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import PlanCard, { PlanCardProps } from '../../components/PlanCard';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import { useAuth } from '../../hooks/useAuth';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';

const iconsPay = [mastercard, visa, diners, discover];

interface PaymentMethodType {
  location: {
    state: {
      plan: PlanCardProps;
    };
  };
}

const schema = yup.object().shape({
  subscription: yup.object().shape({
    credit_card_number: yup
      .string()
      .test(
        'test-number',
        'Número de tarjeta de crédito no es válido', //validation message
        (value) => valid.number(value).isValid,
      )
      .required('Es requerido'),
    credit_card_year: yup
      .string()
      .test(
        'test-year-number',
        'El año de vencimiento no es válido, intente nuevamente.', //validation message
        (value) => {
          return valid.expirationYear(value).isValid;
        },
      )
      .required('Es requerido'),
    credit_card_month: yup
      .string()
      .test(
        'test-month-number',
        'El mes de vencimiento no es válido, intente nuevamente.', //validation message
        (value) => valid.expirationMonth(value).isValid,
      )
      .required('Es requerido'),
    credit_card_cvc: yup
      .string()
      .test(
        'test-cvv-number',
        'El CVV no es válido, intente nuevamente.', //validation message
        (value) => valid.cvv(value).isValid,
      )
      .required('Es requerido'),
    credit_card_name: yup.string().required('Campo requerido'),
    credit_card_cc: yup.string().required('Campo requerido'),
    phone: yup.number().required('Campo requerido'),
    address: yup.string().required('Campo requerido'),
    city: yup.string().required('Campo requerido'),
    country: yup.string().required('Campo requerido'),
  }),
});

const FormStyled = styled.form`
  --horizontal-padding: 0.3125rem;

  box-sizing: border-box;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  width: 100%;

  input {
    box-sizing: border-box;
    height: fit-content;
    padding: 0.625rem;
  }
`;

const Padlock = styled(Footnote)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 15px;

  img {
    margin-right: 13px;
  }
`;

const SelectMethodPay = styled.nav`
  background: ${({ theme }) => theme.colors.simpleWhite};
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 15px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-right: 17px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LegalText = styled.a`
  color: ${({ theme }) => theme.colors.mainAguaColor};
  cursor: initial;
  @media (hover: hover) and (pointer: fine) {
    cursor: pointer;
  }
`;

const CustomH5 = styled(H5)`
  --horizontal-padding: 0.3125rem;

  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
`;

const FlexFieldsContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 100%;
`;

const SecurityFieldsContainer = styled(FlexFieldsContainer)`
  gap: 0.3125rem;

  input {
    flex: 23%;
  }

  input:nth-child(2) {
    flex: 18%;
  }

  input:last-child {
    flex: 28%;
  }
`;

const PaymentMethod: FC<PaymentMethodType> = ({ location }) => {
  useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<PlanCardProps>({
    amount: '',
    id: '',
    interval: '',
    interval_count: '',
    name: '',
    plan_type: '',
    trial_days: '',
  });

  useEffect(() => {
    setCurrentPlan(location?.state.plan);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subscription: {
        credit_card_number: '',
        credit_card_year: '',
        credit_card_month: '',
        credit_card_cvc: '',
        credit_card_name: '',
        credit_card_cc: '',
        phone: '',
        address: '',
        city: '',
        country: 'Colombia',
      },
    },
    resolver: yupResolver(schema),
  });

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const onSubmit = async (data: any) => {
    // try {
    //   setIsLoading(true);
    //   await server.post(`/api/v1/plans/${currentPlan?.id}/subscribe`, { ...data });
    // } finally {
    //   setIsDone(true);
    //   setErrorMessage('');
    // }

    try {
      setIsLoading(true);
      await server.post(`/api/v1/plans/${currentPlan?.id}/subscribe`, { ...data });
      setIsDone(true);
      setErrorMessage('');
    } catch {
      setErrorMessage(
        'Algo salió mal con su método de pago, por favor intente nuevamente, verifique que su tarjeta tiene fondos o si hay problemas con su entidad bancaria.',
      );
      setIsDone(false);
      setIsLoading(false);
    }
  };

  const postMessageToApp = () => {
    navigate('/account');
  };

  const {
    credit_card_number,
    credit_card_year,
    credit_card_month,
    credit_card_cvc,
    credit_card_name,
    credit_card_cc,
    phone,
    address,
    city,
    country,
  } = errors.subscription || {};

  if (isLoading)
    return (
      <Loading
        onPress={postMessageToApp}
        mainTitle={
          isDone
            ? '¡Genial! la compra de tu paquete ha sido completada'
            : 'Por favor espere un momento...'
        }
        // description="Ac ac orci, nulla sit interdum. Sapien nisl vulputate at morbi eget pharetra urna. Tellus nec eu egestas sagittis."
        type={isDone ? 'done' : 'loading'}
        buttonLabel="Regresar a mi cuenta"
      />
    );

  return (
    <Base>
      <NavBar>
        <InfoContainer>
          <H5 color="simpleWhite" fontWeight={fontWeightType.Bold}>
            Paquete seleccionado
          </H5>
          <Caption1
            onClick={() => navigate(-1)}
            color="simpleWhite"
            fontWeight={fontWeightType.Medium}
            style={{ cursor: 'pointer' }}>
            Cambiar paquete
          </Caption1>
        </InfoContainer>
        <Spacer size="12" />
        <PlanCard plan={currentPlan} />
      </NavBar>
      <View>
        <Spacer size="30" />
        {errorMessage ? <ErrorAlert message={errorMessage} /> : null}
        <H5 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Selecciona tu método de pago
        </H5>
        <Spacer size="18" />
        <SelectMethodPay>
          <InfoContainer>
            <Body2 color="grayBlack" fontWeight={fontWeightType.Medium}>
              Tarjeta de crédito
            </Body2>
            <img src={radioButton} alt="radioButton" />
          </InfoContainer>
          <Spacer size="15" />
          <Line />
          <Spacer size="15" />
          <IconsContainer>
            {iconsPay.map((i) => {
              return <img src={i} alt={i} />;
            })}
          </IconsContainer>
        </SelectMethodPay>
        <Spacer size="21" />
        <Padlock fontWeight={fontWeightType.SemiBold}>
          <img src={padlock} alt="padlock" />
          Te encuentras en un espacio seguro.
        </Padlock>
        <Spacer size="20" />
        <CustomH5 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Datos de la tarjeta
        </CustomH5>
        <Spacer size="20" />

        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="subscription.credit_card_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Número de la tarjeta"
                messageError={credit_card_number}
              />
            )}
          />
          <Spacer size="15" />
          <SecurityFieldsContainer>
            <Controller
              name="subscription.credit_card_year"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Año (2024)"
                  messageError={credit_card_year}
                />
              )}
            />
            <Controller
              name="subscription.credit_card_month"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Mes (10)"
                  max={2}
                  messageError={credit_card_month}
                />
              )}
            />
            <Controller
              name="subscription.credit_card_cvc"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Código (CVV)"
                  messageError={credit_card_cvc}
                />
              )}
            />
          </SecurityFieldsContainer>
          <Spacer size="15" />
          <Controller
            name="subscription.credit_card_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="Nombre y Apellido"
                messageError={credit_card_name}
              />
            )}
          />
          <Spacer size="15" />
          <FlexFieldsContainer>
            <Controller
              name="subscription.credit_card_cc"
              control={control}
              render={({ field }) => (
                <TextField {...field} type="text" label="Cédula" messageError={credit_card_cc} />
              )}
            />
            <Controller
              name="subscription.phone"
              control={control}
              render={({ field }) => (
                <TextField {...field} type="text" label="Celular" messageError={phone} />
              )}
            />
          </FlexFieldsContainer>
          <Spacer size="15" />
          <Controller
            name="subscription.address"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="text" label="Dirección" messageError={address} />
            )}
          />
          <Spacer size="15" />
          <FlexFieldsContainer>
            <Controller
              name="subscription.city"
              control={control}
              render={({ field }) => (
                <TextField {...field} type="text" label="Ciudad" messageError={city} />
              )}
            />
            <Controller
              name="subscription.country"
              control={control}
              render={({ field }) => (
                <TextField {...field} type="text" label="Pais" messageError={country} />
              )}
            />
          </FlexFieldsContainer>
          <Spacer size="15" />
          <Caption2 color="mainGray" fontWeight={fontWeightType.Bold}>
            <Checkbox checked={isChecked} onChange={handleOnChange} />
            Al dar click en "Comprar Paquete" usted acepta nuestros{' '}
            <LegalText href="/terms-and-conditions" target="_blank">
              Términos y Condiciones.
            </LegalText>{' '}
            Asimismo, el pago será cargado a su tarjeta de crédito. Puede cancelar su acceso a la
            APP en cualquier momento mediante la opción que se encuentra en 'Mi cuenta'.
          </Caption2>
          <Spacer size="30" />
          <Button disabled={!isChecked} type="submit">
            Comprar Paquete
          </Button>
        </FormStyled>
      </View>
      <Spacer size="30" />
      <Footer />
    </Base>
  );
};

export default PaymentMethod;

export const Head: HeadFC = () => {
  return (
    <>
      <title>Home Page</title>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </>
  );
};
